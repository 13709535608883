













































































































































































import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { EarnHistoryModel } from '@/types/portfolio'
import { SeriesType } from '@/types/kyc'
import { formatRes } from '@/utils/tools'
import { fetchNowAs, fetchNowChart, fetchSuggestChart, fetchEarnList, backTest } from '@/api/portfolio'
import { CommonMudule } from '@/store/modules/common'
import { PortMudule } from '@/store/modules/portfolio'
import { riskTypeOjb3 } from '@/store/modules/option'
import { cloneDeep } from 'lodash'

import MultiFunctionLine from '../components/multiFunctionLine/index.vue'
import LineDialog from './lineDialog.vue'
@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart,
    MultiFunctionLine,
    LineDialog
  }
})
export default class extends Vue {
  dialogShow:boolean = false;
  allEarnData = []
    selectItem = {
      type: ''
    }
    private asObj = {
      customerAssetAllocationTable: [],
      totalAssetAmount: 0,
      suggestAddUsd: 0,
      equivalentCny: 0
    }
    private seriesNow: SeriesType = {
      radius: [0, '60%'],
      type: 'pie',
      top: '8%',
      hoverAnimation: true,
      data: []
    }
   private seriesSuggest: SeriesType = {
     radius: [0, '60%'],
     type: 'pie',
     top: '8%',
     hoverAnimation: true,
     data: [

     ]
   }
   lineSearch = {
     lineSelectArr: [{
       name: '最大',
       count: 0
     }, {
       name: '近一年',
       count: 1
     }, {
       name: '近三年',
       count: 3
     }, {
       name: '近五年',
       count: 5
     }, {
       name: '前次重大回撤至今'
     }],
     lineStatus: 0, // 0 1 3 5 不传
     radioArr: [
       {
         name: '不选择',
         label: null
       }, {
         name: '每三月',
         label: 3
       }, {
         name: '每半年',
         label: 6
       }
     ],
     radioLab: null
   }
      lineArr:any = []

      created() {
        this.initFetch()
      }
      mounted() {
        this.getLineDialogCreatedRef()
      }
      reset() {
        this.$router.push('/portfolio/index')
      }

      get customerName() {
        return CommonMudule.customerName
      }

      async initFetch() {
        PortMudule.getHistoryEarnAction()
        const p = {
          customerId: CommonMudule.customerId
        }
        const resArr = await Promise.all([fetchNowAs(p), fetchNowChart(p), fetchSuggestChart(p), fetchEarnList()])
        if (resArr[0].statusCode === 0) {
          this.$nextTick(() => {
            const temp = cloneDeep(resArr[0].data)
            temp.suggestAddUsd = Number(temp.suggestAddUsd.toFixed(2))
            temp.equivalentCny = temp.equivalentCny.toFixed(2)
            this.asObj = temp
            this.asObj.customerAssetAllocationTable = resArr[0].data.customerAssetAllocationTable
          })
        }

        if (resArr[1].statusCode === 0) {
          const { categoryPercentages } = resArr[1].data
          this.seriesNow.data = formatRes(categoryPercentages).filter((v:any) => v.value)
        }

        if (resArr[2].statusCode === 0) {
          const { categoryPercentages } = resArr[2].data
          this.seriesSuggest.data = formatRes(categoryPercentages).filter((v:any) => v.value)
        }

        if (resArr[3].statusCode === 0 && resArr[3].data) {
          this.allEarnData = resArr[3].data
          // @ts-ignore
          this.selectItem = this.allEarnData.find((item: EarnHistoryModel) => item.riskType === PortMudule.riskType) || {}
          // @ts-ignore
          this.selectItem.type = riskTypeOjb3[this.selectItem.riskType]
        }
      }

      modify() {
        this.$router.push('/portfolio/portKYC/plan')
      }
      /**
    * 折线图按钮切换
    * item 当前项
    * i 索引
    */
      handleClickLineSelect(item:any, i:number) {
        this.lineSearch.lineStatus = i
        this.getLineDialogRef()
      }
      /**
   * 折线图动态再平衡单选
   *
   */
      handleRadioChenge() {
        this.getLineDialogRef()
      }
      /**
    * 获取 lineDialogRef
    */
      getLineDialogRef() {
        (this.$refs.lineDialogRef as any).handleEmit()
      }
      /**
    * 获取 getLineDialogCreatedRef
    */
      async getLineDialogCreatedRef() {
        await (this.$refs.lineDialogRef as any).getblackcatLsit()
      }

      async handleQueryLine(ids:object[]) {
        /**
      * backTestIndicatorIds 回测 复选框id
      * years 最大 1 3 5年 显示数字 0 1 3 5 历史重大回测 默认 null
      * date  最大 1 3 5年 显示null 历史重大回测 默认固定时间 null 2015年9月1日 2015-09-01
      */
        const { lineSearch } = this
        const { lineSelectArr, lineStatus } = lineSearch
        let _backTestIndicatorIds = ''
        for (let i = 0; i < ids.length; i++) {
          const _idx = ids[i]
          _backTestIndicatorIds += `backTestIndicatorIds=${_idx}&`
        }
        const backTestIndicatorIds = _backTestIndicatorIds.slice(0, _backTestIndicatorIds.length - 1)
        let years = null
        let date = null
        if (lineSelectArr.length !== lineStatus + 1) {
          years = lineSelectArr[lineStatus].count
          date = null
        } else {
          years = null
          date = '2015-09-01'
        }
        const params = {
          customerId: CommonMudule.customerId,
          //  backTestIndicatorIds: ids,
          balanceMonths: this.lineSearch.radioLab, // 0 3  6
          date,
          years
        }
        //  return
        const res = await backTest(backTestIndicatorIds, params)

        if (res.statusCode === 0 && res.data) {
          const _data = []
          for (let i = 0; i < res.data.length; i++) {
            const resdata = res.data[i]
            const { date, value } = resdata
            _data.push([date, value])
          }
          this.lineArr = _data
        } else {
          this.lineArr = []
        }
      }
      handleConfirm() {
        this.dialogShow = false
      }
      leftFun() {
        this.dialogShow = false
      }
}
