




































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { SeriesType } from '@/types/kyc'
import { RiskType, EarnHistoryModel } from '@/types/portfolio'
import { formatRes } from '@/utils/tools'
import { fetchNowAs, fetchEarnList, fetchRiskType, riskTypeSave, backTest } from '@/api/portfolio'
import { CommonMudule } from '@/store/modules/common'
import { PortMudule } from '@/store/modules/portfolio'
import { cloneDeep } from 'lodash'
import { riskTypeOjb3 } from '@/store/modules/option'

import MultiFunctionLine from '../components/multiFunctionLine/index.vue'
import LineDialog from './lineDialog.vue'
@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart,
    MultiFunctionLine,
    LineDialog

  }
})
export default class extends Vue {
    numberColumn: number = 0 // 列数
    dialogShow:boolean = false;
    selectItem = {
      type: '',
      earningsInterval: 0,
      annualRateOfReturn: 0
    }
    allEarnData = []
    private asObj = {
      customerAssetAllocationTable: [],
      totalAssetAmount: 0,
      suggestAddUsd: 0,
      equivalentCny: 0
    }
      lineSearch = {
        lineSelectArr: [{
          name: '最大',
          count: 0
        }, {
          name: '近一年',
          count: 1
        }, {
          name: '近三年',
          count: 3
        }, {
          name: '近五年',
          count: 5
        }, {
          name: '前次重大回撤至今'
        }],
        lineStatus: 0, // 0 1 3 5 不传
        radioArr: [
          {
            name: '不选择',
            label: null
          }, {
            name: '每三月',
            label: 3
          }, {
            name: '每半年',
            label: 6
          }
        ],
        radioLab: null
      }
      lineArr:any = []
    private seriesType: SeriesType = {
      radius: [0, '55%'],
      type: 'pie',
      hoverAnimation: true,
      data: []
    }
    earnData = [
      {
        type: '历史组合收益',
        Conservative: '',
        ConservativeModerately: '',
        Steady: '',
        PositiveModerately: '',
        Positive: ''
      },
      {
        type: '组合历史最大回撤',
        Conservative: '',
        ConservativeModerately: '',
        Steady: '',
        PositiveModerately: '',
        Positive: ''
      },
    ]
   showChartType = false

   created() {
     this.initFetch()
   }

   get customerName() {
     return CommonMudule.customerName
   }

   get riskType() {
     return PortMudule.riskType
   }
   handleHoverCol(val:number = 0) {
     this.numberColumn = val
   }
   /**
    * 折线图按钮切换
    * item 当前项
    * i 索引
    */
   handleClickLineSelect(item:any, i:number) {
     this.lineSearch.lineStatus = i
     this.getLineDialogRef()
   }
   /**
   * 折线图动态再平衡单选
   *
   */
   handleRadioChenge() {
     this.getLineDialogRef()
   }
   /**
    * 获取 lineDialogRef
    */
   getLineDialogRef() {
     (this.$refs.lineDialogRef as any).handleEmit()
   }
   /**
    * 获取 getLineDialogCreatedRef
    */
   getLineDialogCreatedRef() {
     (this.$refs.lineDialogRef as any).getblackcatLsit()
   }
    @Watch('riskType', { immediate: true })
   private caclRisk(v: RiskType) {
     if (v) {
       this.selectType(v)
     }
   }

    async change(riskType: RiskType) {
      const p = {
        customerId: CommonMudule.customerId,
        riskType: riskType
      }
      const res = await riskTypeSave(p)
      if (res.success) {
        //  this.$message({
        //    type: 'success',
        //    message: '更新成功'
        //  })
      }
    }

    async selectType(type: RiskType) {
      // @ts-ignore
      this.selectItem = this.allEarnData.find((item: EarnHistoryModel) => item.riskType === type) || {}
      // @ts-ignore
      this.selectItem.type = riskTypeOjb3[this.selectItem.riskType]
      PortMudule.SET_CACL_EARN(this.selectItem.annualRateOfReturn)
      PortMudule.SET_RISKTYPE(type)
      const res = await fetchRiskType({
        riskType: type
      })
      if (res.statusCode === 0 && res.data) {
        this.change(type)
        this.showChartType = true
        this.seriesType.data = formatRes(res.data, 'productTypeName', 'suggestPercentage').filter((v:any) => v.value)
        this.getLineDialogCreatedRef()
      }
    }

    async initFetch() {
      const res2 = await fetchEarnList()
      if (res2.statusCode === 0) {
        const typeObj = {
          type: '历史组合收益',
          Conservative: '',
          ConservativeModerately: '',
          Steady: '',
          PositiveModerately: '',
          Positive: ''
        }
        this.allEarnData = res2.data
        if (PortMudule.riskType) {
          // @ts-ignore
          this.selectItem = this.allEarnData.find((item: EarnHistoryModel) => item.riskType === PortMudule.riskType) || {}
          // @ts-ignore
          this.selectItem.type = riskTypeOjb3[this.selectItem.riskType]
          PortMudule.SET_CACL_EARN(this.selectItem.annualRateOfReturn)
        }
        for (const val of res2.data) {
          for (const key in typeObj) {
            if (val.riskType === key) {
              (this.earnData[0] as any)[key] = val.annualRateOfReturn;
              (this.earnData[1] as any)[key] = val.maxDrawback
            }
          }
        }
      }
    }
    async handleQueryLine(ids:object[]) {
      /**
      * backTestIndicatorIds 回测 复选框id
      * years 最大 1 3 5年 显示数字 0 1 3 5 历史重大回测 默认 null
      * date  最大 1 3 5年 显示null 历史重大回测 默认固定时间 null 2015年9月1日 2015-09-01
      */
      const { lineSearch } = this
      const { lineSelectArr, lineStatus } = lineSearch
      let _backTestIndicatorIds = ''
      for (let i = 0; i < ids.length; i++) {
        const _idx = ids[i]
        _backTestIndicatorIds += `backTestIndicatorIds=${_idx}&`
      }
      const backTestIndicatorIds = _backTestIndicatorIds.slice(0, _backTestIndicatorIds.length - 1)
      let years = null
      let date = null
      if (lineSelectArr.length !== lineStatus + 1) {
        years = lineSelectArr[lineStatus].count
        date = null
      } else {
        years = null
        date = '2015-09-01'
      }
      const params = {
        customerId: CommonMudule.customerId,
        //  backTestIndicatorIds: ids,
        balanceMonths: this.lineSearch.radioLab, // 0 3  6
        date,
        years
      }
      //  return
      const res = await backTest(backTestIndicatorIds, params)

      if (res.statusCode === 0 && res.data) {
        const _data = []
        for (let i = 0; i < res.data.length; i++) {
          const resdata = res.data[i]
          const { date, value } = resdata
          _data.push([date, value])
        }
        this.lineArr = _data
      } else {
        this.lineArr = []
      }
    }
    handleConfirm() {
      this.dialogShow = false
    }
    leftFun() {
      this.dialogShow = false
    }
    adjust() {
      this.showChartType = false
    }
}
