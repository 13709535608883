























import { Vue, Component, Prop } from 'vue-property-decorator'
import { PortMudule } from '@/store/modules/portfolio'

@Component({
  name: 'dialog'
})
export default class extends Vue {
  @Prop({ required: false }) private title!: string
  @Prop({ required: false }) private info!: string
  @Prop({ required: false }) private tip!: string
  @Prop({ required: true }) private show!: boolean
  @Prop({ required: false, default: '是' }) private leftText!: string
  @Prop({ required: false, default: '否' }) private rightText!: string
  @Prop({ required: false, default: () => {} }) private leftFun!: Function
  @Prop({ required: false, default: () => {} }) private rightFun!: Function
}

