







































import { Vue, Component, Prop } from 'vue-property-decorator'
import { available } from '@/api/portfolio'
@Component
export default class LineDialog extends Vue {
    @Prop() dialogShow!: boolean
    @Prop() title!: string
    dialogForm = {
      data: [
        // {
        //   type: '现金指标',
        //   indicators: {
        //     checkList: [],
        //     checkSelectList: []
        //   }
        // }
      ]
    }
    // created() {
    //   this.getblackcatLsit()
    // }
    async getblackcatLsit() {
      const { data } = await available()
      const newData:any = []
      data.forEach((item: any) => {
        const obj:any = {}
        if (item.indicators && item.indicators.length > 0) {
          const indicatorsObj:any = {
            checkSelectList: [item.indicators[0].id]
          }
          const indicatorsArr:any = []
          item.indicators.forEach((w: any) => {
            indicatorsArr.push(w)
          })
          indicatorsObj.checkList = indicatorsArr
          obj.type = item.type
          obj.indicators = indicatorsObj
          newData.push(obj)
        }
      })

      this.dialogForm.data = newData
      this.handleEmit()
    //   this.dialogForm.data = data
    }
    handleEmit() {
      const { data } = this.dialogForm
      let emitFormArr:Array<string> = []
      data && data.forEach((item:any) => {
        emitFormArr = [...emitFormArr, ...item.indicators.checkSelectList]
      })
      this.$emit('on-query-line', emitFormArr)
    }
    handleConfirm() {
      const { data } = this.dialogForm
      const messageArr:any = []
      data && data.forEach((item:any) => {
        if (item.indicators.checkSelectList.length === 0) {
          messageArr.push(item.type)
        }
      })
      if (messageArr.length > 0) {
        const str = messageArr.join('、')
        this.$message.warning(`类型: ${str} 最少选择一项`)
        return
      }
      this.handleEmit()
      this.$emit('on-handle-confirm')
    }
}
