import request from '@/utils/request'
import { GetAssetsInfoParam } from '@/types/common'
/**
 *  四大类数据统计
 */
export const getFourTypeData = (params: object) =>
  request({
    url: '/blackcat/analysis/asset',
    method: 'get',
    params
  })

/**
 * 子类资产列表
 */
export const getAssetsList = async() =>
  request({
    url: '/blackcat/asset/sub',
    method: 'get'
  })

/**
 * 客户资产信息
 */
export const getAssetsInfo = async(params: GetAssetsInfoParam) =>
  request({
    url: '/blackcat/customer/asset/find',
    method: 'get',
    params
  })

/**
 * 资产保存
 */
export const saveAssetItem = (data: any) =>
  request({
    url: '/blackcat/customer/asset/save',
    method: 'post',
    data
  })
