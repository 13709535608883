



































import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { PortMudule } from '@/store/modules/portfolio'
import { CommonMudule } from '@/store/modules/common'
import asGroup from './groupOne/asGroup.vue'
import aloneAsGroup from './groupOne/aloneAsGroup.vue'
import setPlan from './groupOne/setPlan.vue'
import SplitPane from 'vue-splitpane'
import dialogcom from '@/components/dialog/index.vue'
import { deletePlan, getPlan, savePlan } from '@/api/portfolio'
import { getFourTypeData } from '@/api/asKYC'
import { AssetsType } from '@/types/kyc'

@Component({
  name: 'portfolio',
  components: {
    moduleHeader,
    SplitPane,
    aloneAsGroup,
    asGroup,
    setPlan,
    dialogcom,
  },
})
export default class extends Vue {
  alone = '0'; // '1' 单独 2非单独
  showDialog = true
  private isFirst: boolean = true
  private planObj = {
    alone: true,
    customerId: CommonMudule.customerId,
    id: PortMudule.investmentPlanId
  };
  confirm() {}

  resize() {
    // Handle resize
  }

  reset() {
    this.showDialog = true
  }

  created() {
    this.initFetch()
  }
  start() {
    this.showDialog = false
    this.savePro()
  }

  initFetch() {
    this.getInvest()
    CommonMudule.getRMBRateAction()
  }

  private async savePro() {
    const res = await savePlan(this.planObj)
    if (res.statusCode === 0 && res.data) {
      if (res.data) {
        console.log('res111', res.data)
        PortMudule.SET_PLANID(res.data.id)
        if (res.data.alone) {
          this.$router.push('/portfolio/group1')
          PortMudule.SET_ALONE(true)
        } else {
          this.$router.push({
            path: '/portfolio/portKYC/plan',
          })
          PortMudule.SET_ALONE(false)
        }
      }
    }
  }

  private async getInvest() {
    const [res, res2] = await Promise.all([
      getPlan({
        customerId: CommonMudule.customerId,
      }),
      getFourTypeData({
        customerId: CommonMudule.customerId,
      }),
    ])
    if (res.statusCode === 0 && res.data) {
      PortMudule.SET_ALONE(res.data.alone)
      PortMudule.SET_PLANID(res.data.id)
      this.showDialog = false
      if (res.data.alone) {
        this.alone = '1'
        PortMudule.SET_RISKTYPE(res.data.riskType)
      } else {
        if (res2.statusCode === 0 && res2.data) {
          const hasAssets = res2.data.some(
            (item: AssetsType) => item.totalAmount > 0
          )
          if (res.data.riskType && hasAssets) {
            this.alone = '2'
          } else {
            this.$router.push('/portfolio/portKYC/plan')
          }
        }
        PortMudule.SET_RISKTYPE(res.data.riskType)
      }
    } else {
      this.showDialog = true
    }
  }

  async onLeftFun() {
    const res = await deletePlan(
      `investmentPlanId=${PortMudule.investmentPlanId}`
    )
    console.log('deletePlan', res)
    if (res.statusCode === 0 && res.data) {
      this.$message({
        type: 'success',
        message: '操作成功',
      })
      this.showDialog = false
      this.$router.push('/portfolio/index')
      PortMudule.SET_PLANID('')
    } else {
      this.$message({
        type: 'error',
        message: res.errorMsg,
      })
    }
  }

  onRightFun() {
    this.showDialog = false
  }
}
