import { isObject } from 'lodash'
import store from '@/store'
import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators'
import { getHistoryEarn } from '@/api/portfolio'
import { EarnHistoryModel, TableInfo, GroupTwoPlan, RiskType, ProductInfo } from '@/types/portfolio'
import { CommonMudule } from '@/store/modules/common'
import { parsePrice, keepTwoDecimalFullVal } from '@/utils/validate'
import Vue from 'vue'

function total({ v, m }: {v:TableInfo[]; m:GroupTwoPlan}) {
  let outSellTotal = 0
  let outBuyTotal = 0
  let investAll = 0
  let allAmount = 0
  let allOriginTotal = 0
  v.forEach(item => {
    allOriginTotal = allOriginTotal + parsePrice(item.originAmount)
    if (item.subTypeProductActions) {
      item.subTypeProductActions.forEach(subItem => {
        if (subItem.productActions) {
          subItem.productActions.forEach((pro: any) => {
            if (pro.transactionType === 'Sell') {
              // 得到所有卖出之和
              outSellTotal = outSellTotal + parsePrice(pro.amount)
            } else if (pro.transactionType === 'Buy') {
              if (isObject(pro.productName)) {
                // 得到所有买入之和 手动添加的
                if (!pro.automaticInvest) { // 直投买入
                  if (pro.productName.productCurrency !== 'CNY') {
                    outBuyTotal = outBuyTotal + parsePrice(pro.approachCnyAmount)
                  } else {
                    outBuyTotal = outBuyTotal + parsePrice(pro.amount)
                  }
                }
                if (pro.productName.enableAutomaticInvest && pro.automaticInvest) {
                  // 得到所有定投的金额之和
                  investAll = (investAll + parsePrice(pro.investPlanAutoTermAmount))
                } else {
                  pro.productName.investPlanAutoTermAmount = 0
                }
                if (pro.productName.productCurrency !== 'CNY') {
                  allAmount = allAmount + parsePrice(pro.approachCnyAmount)
                } else {
                  allAmount = allAmount + parsePrice(pro.amount)
                }
              } else {
                // 得到所有买入之和 接口获取的
                if (!pro.automaticInvest) { // 直投买入
                  if (pro.product.productCurrency !== 'CNY') {
                    outBuyTotal = outBuyTotal + parsePrice(pro.approachCnyAmount)
                  } else {
                    outBuyTotal = outBuyTotal + parsePrice(pro.amount)
                  }
                }
                if (pro.product.enableAutomaticInvest && pro.automaticInvest) {
                  // 得到所有定投的金额之和
                  investAll = (investAll + parsePrice(pro.investPlanAutoTermAmount))
                } else {
                  pro.product.investPlanAutoTermAmount = 0
                }
                if (pro.product && pro.product.productCurrency !== 'CNY') {
                  allAmount = allAmount + parsePrice(pro.approachCnyAmount)
                } else {
                  allAmount = allAmount + parsePrice(pro.amount)
                }
              }
            }
          })
        }
      })
    }
  })
  return {
    outSellTotal,
    outBuyTotal,
    investAll,
    allAmount,
    allOriginTotal
  }
}
export interface PortState {
  historyEarn: EarnHistoryModel
  alone: boolean
}
@Module({ name: 'port', store, dynamic: true })
class Port extends VuexModule implements PortState {
  alone = false
  historyEarn:EarnHistoryModel = {
    'id': '',
    'tenantId': '',
    'createTime': '',
    'createBy': null,
    'createByName': null,
    'deleted': false,
    'riskType': null,
    'annualRateOfReturn': 0,
    'standardDeviation': 0,
    'maxDrawback': 0,
    'earningsInterval': ''
  }
  public investmentAmountReadonly = 0
  public CurrentMoney:number = 0
  public aloneCurrentMoney:number = 0
  public aloneLastMoney:number = 0
  public LastMoney:number = 0
  public planMoney :number = 0
  public lastPlanMoney :number = 0
  public otherMoney:number = 0
  public allAmount:number = 0
  public allOriginTotal = 0
  public outSellTotal= 0
  public outBuyTotal =0
  // @ts-ignore
  public riskType:RiskType | null = null
  investmentPlanId: string = ''

  @Mutation
  SET_PLANID(v: string) {
    this.investmentPlanId = v
  }

  @Mutation
  SET_ALONE(v: boolean) {
    this.alone = v
  }

  @Mutation
  SET_DIREACT_INVEST_AMOUNT(v: number) {
    this.investmentAmountReadonly = v
  }

  @Mutation
  SET_RISKTYPE(v: RiskType | null) {
    this.riskType = v
  }

  @Mutation
  SET_CACL_EARN(v: any) {
    this.historyEarn.annualRateOfReturn = v
  }

  @Mutation
  SET_HISTORY_EARN(v: EarnHistoryModel) {
    this.historyEarn = v
  }
  @Mutation
  SET_CURRENTMONEY(param: number) {
    this.CurrentMoney = param
  }
  @Mutation
  SET_ALONECURRENTMONEY(param: number) {
    this.aloneCurrentMoney = param
  }
  @Mutation
  SET_ALLAMOUNT(param: number) {
    this.allAmount = param
  }
  @Mutation
  SET_ALLORIGINTAL(param: number) {
    this.allOriginTotal = param
  }
  @Mutation
  SET_ALLSELLTOTAL(param: number) {
    this.outSellTotal = param
  }
  @Mutation
  SET_ALLBUYTOTAL(param: number) {
    this.outBuyTotal = param
  }
  @Mutation
  SET_TABLE_DATA({ v, m, type }: {v:TableInfo[]; m:GroupTwoPlan, type: string}) {
    const { outSellTotal, outBuyTotal, investAll, allAmount, allOriginTotal } = total({ v, m })
    this.CurrentMoney = parsePrice(m.investmentAmount) + outSellTotal
    this.aloneCurrentMoney = parsePrice(m.investmentAmount)
    this.LastMoney = parsePrice(m.investmentAmount) + outSellTotal - outBuyTotal
    this.aloneLastMoney = Number(keepTwoDecimalFullVal(parsePrice(m.investmentAmount) - parsePrice(outBuyTotal)))
    // this.planMoney = investAll
    this.lastPlanMoney = parsePrice(this.planMoney) - investAll
    this.otherMoney = ((this.CurrentMoney + this.planMoney) * 0.15)
    this.allAmount = allAmount
    this.allOriginTotal = allOriginTotal
    this.outSellTotal = outSellTotal
    this.outBuyTotal = outBuyTotal
  }
  @Mutation
  SET_PRICEOBJ(param: number) {
    this.LastMoney = param
  }
  @Mutation
  SET_ALONEPRICEOBJ(param: number) {
    this.aloneLastMoney = param
  }
  @Mutation
  SET_PLANMONEY(param: number) {
    this.planMoney = param
  }
  @Mutation
  SET_LASTPLANMONEY(param: number) {
    this.lastPlanMoney = param
  }
  @Mutation
  SET_OTHERMONEY(param: number) {
    this.otherMoney = param
  }

  @Action
  public async getHistoryEarnAction() {
    const res = await getHistoryEarn({ customerId: CommonMudule.customerId })
    if (res.statusCode === 0 && res.data) {
      this.SET_HISTORY_EARN(res.data)
    } else {
      // new Vue().$message({
      //   type: 'error',
      //   message: '历史组合收益率获取失败'
      // })
    }
  }
}
export const PortMudule = getModule(Port)
